<template>
    <main ref="content">
        <div class="h">
            <div class="outer">
            <img src="../assets/logo.svg" height="40px" width="200px"/>
            <h2 class="header">Booking Confirmation</h2>
            <p style="float: right; margin-top: -13px;">Please present either an electronic or paper copy of your booking confirmation upon check-in</p>
               <p>~</p>
            <div style="display: flex">
                <div style="width: 160px;">
                    <h6>Booking ID :</h6>
                    <h6>Booking Reference No :</h6>
                    <h6>Client :</h6>
                    <h6>Member ID :</h6>
                    <h6>Country Of Residence :</h6>
                    <h6>Property :</h6>
                    <h6>Address :</h6>
                    <br>
                    <h6 >Property Contact No :</h6>
                </div>
                <div style="width: 200px; margin-left: 10px;">
                    <h6 class="b">{{bookingid}}</h6>
                    <h6 class="b">{{bookingreferenceno}}</h6>
                    <h6 class="b">{{guestname}}</h6>
                    <h6 class="b">{{agentid}}</h6>
                    <h6 class="b">{{country}}</h6>
                    <h6 class="b">{{hotelname}}</h6>
                    <h6 class="b">{{address}}</h6>
                    <h6 class="b">{{propertycontactno}}</h6>
                </div>
                <div class="r_t" style="width: 170px;">
                    <h6>Number of Rooms :</h6>
                    <h6>Number of Adults :</h6>
                    <h6>Number of Children :</h6>
                    <h6>Promotion :</h6>
                </div>
                <div class="r_t">
                    <h6 class="c">{{noofrooms}}</h6>
                    <h6 class="c">{{noofadults}}</h6>
                    <h6 class="c">{{noofchildren}}</h6>
                    <h6 class="c">{{promotion}}</h6>
                </div>
            </div>
            <div style="background-color: #dee2e6; margin-top: 10px;">
                <p>Cancellation Policy: This booking is Non-Refundable and cannot be amended or modified. Failure to arrive at your hotel or property will be treated as a NoShow and no refund will be given (Property policy).</p>
            </div>
            <div style="display: flex;">
                <h6 class="b">Arrival :</h6>
                <h6 class="t">{{checkin}}</h6>
                <h6 class="b">Departure :</h6>
                <h6 class="t">{{checkout}}</h6>
            </div>
            <br>
            <div style="text-align: start;">
                <p style="font-weight: bold; color: red;">General Notification:</p>
                <p>The special requests are not covered under the booking cost. Additional charges may apply depending upon the hotel policies. Selecting these requests does not guarantee their availability. Status of your request shall be updated after confirming with the hotel.</p>
                <p>(Exchange rate is as on booking date)*</p>
                <p>In case of any operational issue you can drop mail at hotel@desertlinktours.com or call +971585992119</p>
                <p style="font-weight: bold;">Regards</p> 
                <p style="font-weight: bold;">Desert Link Tours Team</p>
            </div>
            <br>
            <div class="disclaimer">
                <p style="font-weight: bold; color: red;">DISCLAIMER: </p>
                <p>This communication (including any accompanying documents / attachments) is intended only for the use of the addressee(s) and contains information that is PRIVILEGED AND CONFIDENTIAL. If you are not the intended recipient, you are notified that any dissemination and/or copying of this e-mail is strictly prohibited and you are requested to delete this e-mail immediately. Communicating through e-mail is not secure and capable of interception & delays. Any one communicating with DLT by e-mail accepts the risks involved and their consequences. While this e-mail has been checked for all known viruses, the addressee should also scan for viruses and notify the originator. Thank you for your cooperation.</p>
            </div>
            </div>
        </div>
    </main>
</template>

<script>
import html2pdf from "html2pdf.js";
import axios from "axios";

export default {
    name: "Booking",
    data() {
        return {
            bookingid: 0,
            bookingreferenceno: 0,
            agentid: '',
            checkin: '',
            checkout: '',
            noofrooms: 0, 
            noofadults: 0,
            noofchildren: 0,
            promotion: 0, 
            hotelname: '',
            address: '', 
            propertycontactno: '',
            guestname: '',
            country: '',
        }
    },
    props: {
    no: {
      type: String,
      required: true,
    },
  },
    methods: {
    download() {
      //html2pdf(this.$refs.content);
      //html2pdf.output('abc.pdf');
      //html2pdf = new html2pdf(this.$refs.content, 'A4', 'en');
      //html2pdf.output('abc.pdf');
      html2pdf().from(this.$refs.content).toPdf().saveAs('booking.pdf');
    },
    async getMailInfo(s) {
        try {
        const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/FinalMailInfo/${s}`
        );
        this.bookingid = response.data[0].bookingid;
        this.bookingreferenceno = response.data[0].bookingreferenceno;
        this.agentid = response.data[0].agentid;
        this.checkin = response.data[0].checkin;
        this.checkout = response.data[0].checkout;
        this.noofrooms = response.data[0].noofrooms;
        this.noofadults = response.data[0].noofadults;
        this.noofchildren = response.data[0].noofchildren;
        this.promotion = response.data[0].promotion;
        this.hotelname = response.data[0].hotelname;
        this.address = response.data[0].address;
        this.propertycontactno = response.data[0].propertycontactno;
        this.guestname = response.data[0].guestname;
        this.country = response.data[0].country;
        //console.log(this.item);
        } catch (err) {
        console.log(err);
        }
    },
    },
    created() {
    //console.log(this.no);
    this.getMailInfo(this.no);
    //this.postEmail();
    //this.getBookingID();
  },
};
</script>

<style scoped>
h6 {
    font-size: 14px;
}
p{
    font-size: small;
    margin-bottom: 5px;
    text-align: justify;
}
.outer{
    outline-style: solid;
    outline-color:black;
    padding: 10px 10px 10px 10px;
}
.h{
    min-height: 29cm;
    max-width: 21cm;
    margin: 0px auto;
    padding: 1cm 1cm 1cm 1cm;
    background-color: white;
    
}
.header{
    float: right;
    font-weight: bold;
    color: red;
}
.logo{
    margin-top: 15px;
    min-height: "20px";
    max-width: "100%";
    background-size: 100px 20px;
    background-image: url("../assets/logo.svg");
    background-repeat: repeat-x;
    background-color: #e9ecef;
}
.b{
    font-weight: bold;
}
.c{
    font-weight: bold;
    text-align: center;
    outline-style: solid;
    outline-color:white;
}
.r_t{
    background-color: #dee2e6; 
    width: 190px;
    padding: 10px 10px 10px 10px;
}
.t{
    font-weight: bold; 
    background-color: #dee2e6; 
    width: 200px; 
    text-align: center;
    margin: 0px 10px 0px 10px;
    padding: 3px;
}
.disclaimer{
    outline-style: solid;
    outline-color:#dee2e6;
    padding: 10px 10px 1px 10px;
}
</style>
